import { render, staticRenderFns } from "./UserContentHolder.vue?vue&type=template&id=1d8c6b05&scoped=true&"
import script from "./UserContentHolder.vue?vue&type=script&lang=js&"
export * from "./UserContentHolder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d8c6b05",
  null
  
)

export default component.exports