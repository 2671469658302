<template>
    <div class="user-content-holder-component">
        <div class="uk-card uk-card-default uk-box-shadow-small">
            <div class="uk-card-body">
                <SearchBar id="user-content-search-comoponent"></SearchBar>
                <div class="uk-flex-nowrap uk-grid-match" uk-grid uk-overflow-auto>
                    <ContentCard
                        v-for="(content, index) in filteredContent" :key="content.idContent"
                        :content_name="content.titlu"
                        :purchase_date="content.purchase_date"
                        :revoke_date="content.revoke_date"
                        v-on:content-select="selectContent(index)"
                        v-on:content-deselect="deselectContent(content)">
                    </ContentCard>
                </div>

                <div class="uk-card-footer uk-flex uk-flex-center uk-padding-small">
                    <button v-on:click="revokeContent()" class="uk-button uk-button-danger uk-margin-remove" type="button">Revoca accesul</button>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import Axios from 'axios';
import {SERVER_URL} from '@/.env.js';
import Cookies from 'js-cookie/src/js.cookie';
import Cacher from '@/services/Cacher';
import { Notifier } from '@/services/Notifier';
import ContentCard from '@/components/Reusables/ContentCard';
import SearchBar from '@/components/Reusables/SearchBar';

export default {
    name: 'UserContentHolder',
    components: {
        ContentCard,
        SearchBar
    },
    props: {
        userId: Number
    },
    data: function() {
        return {
            contents: [],
            selectedContents: [],
        }
    },
    computed: {
        filteredContent() {
            let searchedContent = this.$store.state.searchModule.searchedTerm.toLowerCase();
            let filtered = this.contents.filter(element => {
                return element.titlu.toLowerCase().indexOf(searchedContent) > -1;
            });

            return filtered;
        }
    },
    methods: {
        getUserContent() {
            let initiator_id = Cacher.getFromCache('userId');
            let org_id = this.$store.state.organizationModule.org.idOrg;
            let token = Cookies.get('X-XSRF-TOKEN');

            Axios.put(
                SERVER_URL + '/org/api/get_user_content',
                {
                    initiator_id: initiator_id,
                    org_id: org_id,
                    user_id: this.userId
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                this.contents = response.data.contents;
            })
            .catch(reason => {
                console.log(reason.response.data.msg);
            });
        },

        selectContent(index) {
            this.selectedContents.push(this.filteredContent[index]);
        },

        deselectContent(content) {
            this.selectedContents.splice(
                this.selectedContents.indexOf(content),
                1
            );
        },

        revokeContent() {
            let token = Cookies.get('X-XSRF-TOKEN');
            let user_id = this.userId;
            let initiator_id = Cacher.getFromCache('userId');
            let org_id = this.$store.state.organizationModule.org.idOrg;
            let contents = this.selectedContents.map(content => content.idContent);

            Axios.post(
                SERVER_URL + '/org/api/revoke_content',
                {
                    initiator_id: initiator_id,
                    org_id: org_id,
                    user_id: user_id,
                    contents: contents
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                Notifier.notify(response.data.msg, 'success');

                this.selectedContents = [];

                this.getUserContent();
            })
            .catch(reason => {
                console.log(reason.response.data.msg);
                Notifier.notify('Nu am putu revoca accesul. Mai incercati!', 'danger');
            });
        }
    },
    mounted() {
        this.getUserContent();
        this.$store.commit('changeTerm', '');
    }
}
</script>

<style lang="less" scoped>

</style>