<!-- Use this component to hold together the ContentCard components -->
<template>
    <div class="content-holder-component">
        <SearchBar id="content-search-component" v-on:search-term-changed="filterContent()"></SearchBar>
        <div id="grid-container" class="uk-flex-nowrap uk-grid-match" uk-grid uk-overflow-auto>
            <ContentCard
                v-for="(indiv_content, index) in this.filteredContent" :key="indiv_content.content_id"
                :content_name="indiv_content.ContentName"
                :purchase_date="indiv_content.purchase_date"
                :revoke_date="indiv_content.revoke_date"
                v-on:content-select="selectContent(index)"
                v-on:content-deselect="deselectContent(indiv_content.content_id)">

            </ContentCard>
        </div>
        <div class="uk-flex uk-flex-middle uk-flex-center">
            <button @click="toggleModal" v-if="content.length != 0" class="uk-button uk-button-primary" type="button" uk-toggle="target: #asign-modal">Asigneaza continutul</button>
            <button v-if="content.length == 0" class="uk-button uk-button-danger" type="button">
                <a v-on:click="notAvailableNotify()" :href="buy_url" target="_blank">Cumpara continut</a>
            </button>
        </div>

        <!-- This modal will be used to select the employees -->
        <div id="asign-modal" class="uk-modal-container" uk-modal="bg-close: false;">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
                
                <div class="uk-modal-header">
                    <h3 class="uk-text-center">Selecteaza angajatii</h3>
                </div>
                
                <div class="uk-modal-body uk-flex uk-flex-middle uk-flex-center uk-flex-column">
                    <h4 class="uk-text-center">Ati selectat urmatorul continut:</h4>
                    <div v-if="selectedContent.length != 0" class="uk-grid-small uk-flex-center" uk-grid>
                        <p
                            class="uk-border-pill uk-text-center uk-margin-small-right selected-content"
                            v-for="(element) in this.selectedContent" :key="element.content_id"
                            v-on:click="deselectContent(element.content_id)"
                        >
                        <fa-icon class="close-icon uk-margin-right" icon="times-circle"></fa-icon>
                        {{ element.ContentName }}
                        </p>
                    </div>

                    <h4 class="uk-text-center">Alegeti persoanele din organizatie:</h4>           
                    <!-- Search bar with icon -->
                    <div class="uk-inline uk-width-1-2@l">
                        <span class="uk-form-icon"><fa-icon icon="search"></fa-icon></span>
                        <input v-model="searchedUser" type="text" name="org_user_input" id="org_user_input" class="uk-input uk-border-pill" placeholder="Email-ul persoanei">
                    </div>

                    <!-- This next section will contain a filtered list of emails that's updated as the admin types the email of a person -->
                    <div v-if="filteredUsers.length != 0" class="uk-grid-small uk-flex-center uk-margin-small-top" uk-grid>
                        <p
                            class="uk-border-pill uk-text-center uk-margin-small-right unselected-user"
                            v-for="(element, index) in this.filteredUsers" :key="element.id"
                            v-on:click="selectUser(index)"
                        >
                        {{ element.email }}
                        </p>
                    </div>

                    <!-- Finally this section will show all the selected users -->
                    <h4 v-if="this.selectedUsers.length > 0" class="uk-text-center">Ati selectat urmatoarele persoane:</h4>
                    <div v-if="this.selectedUsers.length > 0" class="uk-grid-small uk-flex-center uk-margin-small-top" uk-grid>
                        <p
                            class="uk-border-pill uk-text-center uk-margin-small-right selected-user"
                            v-for="(element, index) in this.selectedUsers" :key="element.id"
                            v-on:click="deselectUser(index)"
                        >
                        <fa-icon class="close-icon uk-margin-right" icon="times-circle"></fa-icon>
                        {{ element.email }}
                        </p>
                    </div>
                </div>

                <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-center">
                    <button v-if="!asign_load" v-on:click="asignContent()" class="uk-button uk-button-primary" type="button">Asigneaza acum</button>
                    <div v-if="asign_load" uk-spinner="ratio: 2"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentCard from "@/components/Reusables/ContentCard";
import Cacher from "@/services/Cacher";
import { Notifier } from "@/services/Notifier";
import { SERVER_URL } from "@/.env";
import Cookies from "../../../node_modules/js-cookie/src/js.cookie"
import Axios from 'axios';
import { mapGetters } from 'vuex';
import SearchBar from '@/components/Reusables/SearchBar';

export default {
    name: 'ContentHolder',
    components: {
        ContentCard,
        SearchBar
    },
    props: {
        product_id: Number,
        buy_url: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            searchedUser: '',
            asign_load: false,
            showModal: false
        }
    },
    computed: {
        ...mapGetters({
            users: 'users',
            selectedUsers: 'selectedUsers',
            content: 'contentModule/content',
            selectedContent: 'contentModule/selectedContent',
            filteredContent: 'contentModule/filteredContent'
        }),

        filteredUsers: function() {
            this.$store.dispatch('filterUsers', this.searchedUser);
            return this.$store.state.organizationModule.filteredUsers;
        }
    },
    watch: {
        product_id: function() {
            this.updateContent();
        }
    },
    methods: {
        async getOrgContent() {
            await this.$store.dispatch('contentModule/loadContent', {productId: this.product_id});
            this.$store.dispatch('contentModule/filterContent', '');
        },
       
        async getUsers() {
           if (this.users.length == 0) {
               await this.$store.dispatch('loadUsers');
           }
        },

       updateContent: function(){},

        /**
         * Will return a localized version of a datetime string
         * 
         * @param {String} date The date string
         */
        localizedDate: function(date) {
            return new Date(date).toLocaleString('ro');
        },
    
        selectContent(index) {
            this.$store.commit('contentModule/selectContent', index);
        },

        deselectContent(content_id) {
            this.$store.commit('contentModule/deselectContent', content_id);
        },

        notAvailableNotify: function() {
            if (this.buy_url == null) {
                Notifier.notify("Va fi disponibil in curand", "warning");
            }
        },

        /**
         * Removes the selected user from the users[] array and inserts it in
         * the selected_users[] array.
         * 
         * @param {Number} index
         */
        selectUser(index) {
            this.$store.dispatch('selectUser', index);
        },

        deselectUser(index) {
            this.$store.dispatch('deselectUser', index);
        },

        filterContent() {
            this.$store.dispatch('contentModule/filterContent', this.$store.state.searchModule.searchedTerm);
        },

        /**
         * This method will call the api for asigning content
         */
        asignContent() {
            // First let's chacge the button with a spinner
            this.asign_load = true;

            let token = Cookies.get('X-XSRF-TOKEN');

            Axios.post(
                SERVER_URL + '/org/api/asign_content',
                {
                    initiator_id: Cacher.getFromCache('userId'),
                    org_id: this.$store.state.organizationModule.org.idOrg,
                    users: this.selectedUsers,
                    contents: this.selectedContent
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                Notifier.notify(response.data.msg, 'success');
                this.asign_load = false;

                // Now we reset the selected users
                this.$store.commit('resetUsers');
            })
            .catch(reason => {
                console.log(reason);
                Notifier.notify('Am intampinat o eroare la asignarea continutului', 'danger');
                this.asign_load = false;
            });
        },

        toggleModal() {
            this.showModal = !(this.showModal);
        }
    },
    mounted() {
        this.getUsers();
        this.getOrgContent();
    },
    created() {
        this.updateContent = function(){
            this.getOrgContent();
        };
    }
}
</script>

<style lang="less" scoped>
    @import "@/assets/helpers.less";

    .uk-button {
        margin-top: 1em;
        font-family: 'Lato';
        font-weight: 700;
        padding: .3em;
        border-radius: .15em;
        .fluid-font(1, 15, 320, 1920);

        a {
            text-decoration: none;
            color: white;
        }
    }

    .uk-modal-header {
        background-color: white;
        border-bottom: 1px solid rgba(128, 128, 128, 0.7);

        h3 {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(1, 15, 320, 1920);
        }
    }

    .uk-modal-body {
        h4 {
            font-family: 'Lato';
            .fluid-font(1, 12, 320, 1920);
        }

        input {
            font-family: 'Lato';
            .fluid-font(1, 8, 320, 1920);
        }

        p {
            font-family: 'Lato';
            .fluid-font(.9, 5, 320, 1920);
        }
    }

    .uk-modal-footer {
        background-color: white;
        border-top: 1px solid rgba(128, 128, 128, 0.7);

        .uk-button {
            margin: 0;
            .fluid-font(1, 12, 320, 1920);
        }
    }

    .selected-content, .unselected-user {
        background-color: rgb(235, 244, 255);
        padding: .15em;
        font-family: 'Lato';
        font-weight: 700;
        color: black;
        transition: all .3s ease-in-out;
    }

    .selected-user {
        background-color: rgb(209, 255, 219);
        padding: .15em;
        font-family: 'Lato';
        font-weight: 700;
        color: black;
        transition: all .3s ease-in-out;
    }

    .selected-content:hover {
        .close-icon {
            color: red;
            transition: all .3s ease-in-out;
        }

        cursor: pointer;
        background-color: rgb(255, 235, 235);
        transition: all .3s ease-in-out;
    }

    .unselected-user:hover {
        cursor: pointer;
        background-color: rgb(209, 255, 219);
        transition: all .3s ease-in-out;
    }

    .selected-user:hover {
        .close-icon {
            color: red;
            transition: all .3s ease-in-out;
        }

        cursor: pointer;
        background-color: rgb(255, 235, 235);
        transition: all .3s ease-in-out;
    }
</style>