<!-- This component will be used to render the purchased content from different products -->
<template>
    <div class="content-component">
        <div class="uk-card uk-box-shadow-small uk-box-shadow-hover-medium uk-margin-bottom" v-on:click="markAsSelected">
            <div class="uk-card-body">
                <h3 class="uk-card-title uk-text-center">{{ content_name }}</h3>
                <p class="uk-text-center">Cumparat la: {{ localizedPurchaseDate }}</p>
                <p class="uk-text-center uk-text-danger" v-if="revoke_date">Poate fi reasignat incepand cu: {{ localizedRevokeDate }}</p>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'ContentCard',
    props: {
        content_name: String,
        purchase_date: String,
        revoke_date: String
    },
    computed: {
        localizedPurchaseDate: function() {
            return this.localizedDate(this.purchase_date);
        },

        localizedRevokeDate: function() {
            return this.localizedDate(this.revoke_date);
        }
    },
    methods: {
        markAsSelected: function(event) {
            if (event.currentTarget.classList.contains('uk-card')) {
                console.log('helo')
                event.currentTarget.classList.remove('uk-card');
                event.currentTarget.classList.add('uk-card-primary');
                this.$emit('content-select');
            }
            else {
                event.currentTarget.classList.add('uk-card');
                event.currentTarget.classList.remove('uk-card-primary');
                this.$emit('content-deselect');
            }
        },

        localizedDate: function(date) {
            return new Date(date).toLocaleDateString('ro');
        }
    }
}
</script>

<style lang="less" scoped>
    .uk-card {
        background-color: white;
        border-radius: .5em;
        width: 25vw;
        cursor: pointer;
    }

    .uk-card-primary {
        border-radius: .5em;
        width: 25vw;
        cursor: pointer;
    }
</style>