<!-- This component is meant to hold all the logic related to product and service management -->
<template>
    <div class="service-manager-component">
        <section v-if="!loading" class="uk-section uk-padding">
            <h4 class="uk-text-center uk-margin-remove-top">Administreaza produsele si continutul organizatiei</h4>
            <div class="uk-grid-match" uk-grid>
                <!-- Sidebar with products -->
                <div class="uk-width-1-4@m">
                    <ProductCard
                        v-for="(product, index) in this.products" :key="product.idProduct"
                        :product="product"
                        v-on:click.native="changeProduct(index)">
                    </ProductCard>
                </div>

                <!-- Management section per product -->
                <div id="#management-card" class="uk-width-expand uk-margin-left uk-card uk-card-default uk-card-body uk-box-shadow-small uk-margin-bottom">
                    <h4 class="uk-card-title uk-text-center">Continutul {{products[currentProductIndex].ProductName}}</h4>
                    <keep-alive>
                        <component 
                            v-bind:is="ContentHolder" 
                            v-bind="{
                                product_id: this.currentProductIndex + 1, 
                                buy_url: this.products[this.currentProductIndex].url
                            }">
                        </component>
                    </keep-alive>
                </div>
            </div>
        </section>

        <!-- Spinner to show while loading data -->
        <div v-if="loading" class="uk-section uk-flex uk-flex-center uk-flex-middle">
            <div uk-spinner="ratio: 5"></div>
        </div>
    </div>      
</template>

<script>
import ProductCard from "@/components/Reusables/ProductCard";
import ContentHolder from "@/components/Reusables/ContentHolder";

export default {
    name: 'ServiceManager',
    components: {
        ProductCard,
        ContentHolder
    },
    data: function() {
        return {
            org: this.$store.state.organizationModule.org,
            loading: true,
            ContentHolder: ContentHolder,
            currentProductIndex: 0
        }       
    },
    computed: {
        products: function() {
            return this.$store.state.organizationModule.productModule.products;
        }
    },
    methods: {
        /**
         * Will do the first time retrieval of the products from the server.
         */
        async getProducts() {
            await this.$store.dispatch('productModule/loadProducts');
            this.loading = false;
        },

        changeProduct(index) {
            this.currentProductIndex = index;
        }
    },
    mounted() {
        // Get the products for this organization
        this.getProducts();
    }
}
</script>

<style lang="less" scoped>
    @import "@/assets/helpers.less";

    .uk-section {
        h4 {
            font-family: 'Lato';
            .fluid-font(1.8, 30, 320, 1920);
        }
    }

    .uk-card-body h4 {
        font-family: 'Lato';
        font-weight: 500;
        .fluid-font(2, 30, 320, 1920);
    }

    #management-card {
        width: 100%;
    }
</style>