<!-- This component is used to display the details of an organization -->
<template>
    <div class="organization-details-component">
        <h3 class="uk-text-center">{{ org.orgName }}</h3>
        <ServiceManager></ServiceManager>
        <UserManager></UserManager>
    </div>    
</template>

<script>
import ServiceManager from '@/components/Reusables/ServiceManager';
import UserManager from '@/components/Reusables/UserManager';

export default {
    name: 'OrganizationDetails',
    components: {
        ServiceManager,
        UserManager
    },
    data: function() {
        return {
            org: Object
        }
    },
    mounted: function() {
        this.org = this.$store.state.organizationModule.org;
    }
}
</script>

<style lang="less" scoped>
    @import '@/assets/helpers.less';

    h3 {
            font-family: 'Lato';
            .fluid-font(2.3, 35, 320, 1920);
        }
</style>