<template>
    <div class="search-bar-component">
        <div class="uk-inline">
            <span class="uk-form-icon"><fa-icon icon="search"></fa-icon></span>
            <input v-on:input="propgateSearch()" v-model="searchedTerm" type="text" name="org_user_input" class="uk-input uk-border-pill" placeholder="Cauta...">
        </div>
    </div>    
</template>

<script>
export default {
    name: 'SearchBar',
    data: function() {
        return {
            searchedTerm: ''
        }
    },
    methods: {
        propgateSearch(){
            this.$store.commit('changeTerm', this.searchedTerm);
            this.$emit('search-term-changed');
        }
    }
}
</script>

<style lang="less" scoped>
    @import '@/assets/helpers.less';

    .uk-input {
        font-family: 'Lato';
        .fluid-font(1, 10, 320, 1920);
    }
</style>