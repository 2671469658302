<!-- This component handles the management of users inside an organization -->
<template>
    <div class="user-manager-component">
        <section class="uk-section">
            <h4 class="uk-text-center">Administreaza persoanele din organizatie</h4>
            <div class="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-margin-bottom">
                <div class="uk-inline uk-width-1-2@l">
                    <span class="uk-form-icon"><fa-icon icon="search"></fa-icon></span>
                    <input
                        v-on:focus="showUsers()"
                        v-model="searched_user" 
                        type="text" name="org_user_input" class="uk-input uk-border-pill uk-form-large" placeholder="Email-ul persoanei">
                </div>
                <div v-if='focused' id="users-dropdown" class="uk-width-1-2@l uk-margin-top">
                    <button v-on:click="addUser()" class="uk-button uk-button-default uk-border-pill uk-width-1-1">Adauga o noua persoana</button>
                    <p 
                        class="uk-padding-small uk-margin-top-small uk-box-shadow-small uk-border-pill" 
                        v-for="user in this.filteredUsers" :key="user.id"
                        v-on:click="selectUser(user)"
                    >
                        {{ user.email }}
                    </p>
                </div>
            </div>

            <!-- This section displays details about a user -->
            <div class="uk-padding-small" v-if="this.selected_user" uk-grid>
                <div id="user-management-panel" class="uk-width-1-4@l">
                    <div class="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-padding-small">
                        <h5 class="uk-text-center">{{ selected_user.email }}</h5>
                        <div class="uk-flex uk-flex-center">
                            <button v-on:click="removeUser()" class="uk-button uk-button-danger">Elimina din organizatie</button>
                        </div>
                    </div>
                </div>

                <UserContentHolder :userId="this.selected_user.id" class="uk-width-expand"></UserContentHolder>
            </div>
        </section>
    </div>
</template>

<script>
import Cacher from '@/services/Cacher';
import { Notifier } from '@/services/Notifier';
import Axios from 'axios';
import * as Cookies from 'js-cookie/src/js.cookie';
import { SERVER_URL } from '@/.env.js';
import UserContentHolder from '@/components/Reusables/UserContentHolder';
import { mapGetters } from 'vuex';

export default {
    name: 'UserManager',
    components: {
        UserContentHolder
    },
    data: function() {
        return {
            searched_user: '',
            focused: false,
            selected_user: undefined
        }
    },
    methods: {
        async getUsers() {
            this.$store.dispatch('loadUsers');
        },

        showUsers() {
            this.$store.commit('resetUsers');
            this.focused = true;
            this.selected_user = undefined;
        },

        addUser() {
            Axios.post(
                SERVER_URL + '/org/api/add_user',
                {
                    initiator_id: Cacher.getFromCache('userId'),
                    org_id: this.$store.state.organizationModule.org.idOrg,
                    email: this.searched_user
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                Notifier.notify(response.data.msg, 'success');
            })
            .catch(reason => {
                console.error(reason.response.data.msg);
                Notifier.notify(reason.response.data.msg, 'warning');
            })
            .finally(() => {
                this.$store.dispatch('loadUsers');
            });
        },

        removeUser() {
            Axios.post(
                SERVER_URL + '/org/api/remove_user',
                {
                    initiator_id: Cacher.getFromCache('userId'),
                    org_id: this.$store.state.organizationModule.org.idOrg,
                    user_id: this.selected_user.id
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                Notifier.notify(response.data.msg, 'success');

                this.selected_user = undefined;
                this.$store.dispatch('loadUsers');
            })
            .catch(reason => {
                console.error(reason.response.data.msg);
                Notifier.notify(reason.response.data.msg, 'danger');
            });
        },

        selectUser(user) {
            this.selected_user = user;
            this.focused = false;
        }
    },
    computed: {
        ...mapGetters({
            users: 'users'
        }),

        filteredUsers: function() {
            return this.users.filter(element => {
                return element.email.toLowerCase().indexOf(this.searched_user.toLowerCase()) > -1
            }).slice(0, 10);
        }
    },
    mounted: function() {
        this.getUsers();
    }
}
</script>

<style lang="less" scoped>
    @import '@/assets/helpers.less';

    .uk-section {
        h4 {
            font-family: 'Lato';
            .fluid-font(1.8, 30, 320, 1920);
        }
    }

    .uk-input {
        font-family: 'Lato';
        .fluid-font(1, 2.5, 320, 1920);
    }

    #users-dropdown {
        max-height: 30vh;
        overflow: auto;

        p {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(1, 2.5, 320, 1920);
            background-color: #f8f8f8;
            cursor: pointer;
            transition: all .2s ease-in-out;
        }

        button {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(1.2, 2.5, 320, 1920);
            color: white;
            background-color: #32d296;
            padding-top: .2em;
            padding-bottom: .2em;
        }

        p:hover {
            background-color: #1e87f0;
            color: white;
            transition: all .2s ease-in-out;
        }
    }

    #user-management-panel {
        .uk-card {
            border-radius: .2em;
        }

        h5 {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(.8, 8, 320, 1920);
        }

        button {
            font-family: 'Lato';
            .fluid-font(1,5,320,1920);
        }
    }
</style>