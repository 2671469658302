<!-- This component holds information about a product we offer -->
<template>
    <div class="product-card-component uk-grid-small uk-flex">
        <div class="uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-box-shadow-hover-large uk-margin-bottom uk-width-1-1">

            <!-- Container for title and label -->
            <div class="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <h3 class="uk-card-title uk-text-center uk-margin-remove">{{ product.ProductName }}</h3>
                <div v-if="this.active != undefined">
                    <span v-if="active" class="uk-label-success">Licenta Activa</span>
                    <span v-if="!active" class="uk-label-danger">Licenta Inactiva</span>
                </div>
            </div>
            
            <!-- Product details section -->
            <div v-if="active" class="uk-flex uk-flex-center uk-flex-middle uk-margin-top">
                <p>Expira la: {{ localizedExpiryDate(this.product.end_date) }}</p>
            </div>
        </div>
    </div>    
</template>

<script>
import {Notifier} from '@/services/Notifier';

export default {
    name: 'ProductCard',
    props: {
        product: Object
    },
    data: function() {
        return {
            active: Boolean
        }
    },
    created() {
        // See if product license is active
        let expiry_date = undefined;
        if (this.product.end_date) {
            expiry_date = new Date(this.product.end_date).getTime();

            if (expiry_date >= new Date().getTime()) {
                this.active = true;
            }
            else {
                this.active = false;
            }
        }
        else {
            this.active = undefined;
        }
    },
    methods: {
        /**
         * Will return a localized version of a datetime string
         * 
         * @param {String} date The date string
         */
        localizedExpiryDate: function(date) {
            return new Date(date).toLocaleString('ro');
        },

        /**
         * If the product is not active, the user will be redirected to a place
         * where he can buy it
         */
        redirectToBuy: function() {
            if (!this.active) {

                if (this.product.url == null) {
                    Notifier.notify('Momentan nu este valabil', 'warning');
                    return;
                }

                window.open(this.product.url, '_blank');
            }
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../../assets/helpers.less";

    .uk-card {
        cursor: pointer;

        h3 {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(1, 12, 320, 1920);
        }

        span {
            font-family: 'Lato';
            font-weight: 700;
            .fluid-font(1, 8, 320, 1920);
            padding: .1em;
        }

        p {
            font-family: 'Lato';
            .fluid-font(1, 5, 320, 1920);
        }
    }
</style>